.contact-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: fit-content;
  margin-top: 50px;
  width: 100%;
  padding: 5vh 8%;
  background-color: var(--color-primary);
}
.contact-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  text-transform: uppercase;
}

.contact-header > h5 {
  font-size: 20px;
}
.contact-header > h2 {
  font-size: 35px;
  color: #fff;

  font-family: "Bella";
}

.contact_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.contact_options {
  display: flex;
  gap: 1.2rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.contact_option {
  padding: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
  border-radius: 20px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.contact_option_icon {
  font-size: 1.5rem;
}

.contact_option a {
  display: inline-block;
  font-size: 0.8rem;
  color: #ffffff;
}

/******************* FORM ***********************/

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 90%;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid #fff;
  resize: none;
  color: white;
}

::-webkit-input-placeholder { /* Chrome, Opera, Safari */
    color: white;
}
.formbtn {
  width: max-content;
  display: inline-block;

  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid #fff;
  transition: var(--transition);
  color: #fff;
  font-size: 18px;
  background-color: transparent;
    font-family: "Bella";
}
/* media quries (medium)*/

@media screen and (max-width: 1024px) {
  .contact_container.container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .contact_container {
    width: 100%;
  }
}

/* media quries (small)*/

@media screen and (max-width: 600px) {
  .contact_container {
    width: 100%;
    gap: 2rem;
  }
}
@media screen and (max-width: 480px) {
  .contact_options {
    align-items: flex-start;
    justify-content: baseline;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 5px;
  }
}
