.experienceWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 30px;
  height: fit-content;
  margin: 50px 0px;
  padding: 0px 8%;
}
.experienceHeading {
  display: flex;
  flex-direction: column;

  gap: 10px;
  text-transform: uppercase;
}

.experienceHeading > h2 {
  font-size: 35px;
  font-family: "Bella";
}

.ExperienceContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.ExperienceContentWrapper > .IndividualExperiences {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.ExperienceContentWrapper > .IndividualExperiences > h2 {
  color: var(--color-primary);
  font-family: "Bella";
}

.ExperienceContentWrapper > .IndividualExperiences > div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.ExperienceContentWrapper > .IndividualExperiences > div > h2 {
  font-family: "Bella";
}
.ExperienceContentWrapper > .IndividualExperiences > div > p {
  min-width: 165px;

  display: flex;
  justify-content: end;
}
@media screen and (max-width: 470px) {
  .ExperienceContentWrapper > .IndividualExperiences > h2 {
    font-size: 1.5rem;
  }
  .ExperienceContentWrapper > .IndividualExperiences > div > h2 {
    font-size: 1.2rem;
  }
  .ExperienceContentWrapper > .IndividualExperiences p {
    font-size: 14px;
  }
}
