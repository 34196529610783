.whole-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;

}

/*-------------CTA---------------*/

.cta {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.cta-btn1 {
  border: 2px solid var(--color-primary);
  border-radius: 10px;
  width: 280px;
  padding:13px 10px;
  text-transform: uppercase;
  font-weight: 500;
   font-family: "Bella";
}
.cta .cta-btn1 {
  background-color: var(--color-primary);
  color: #fff;
  text-align: center;
}
.cta .cta-btn1:hover {
 transform: scale(1.1);
}

/*---header socials -------*/

.header_socials {
  display: flex;
  align-items: center;
  gap: 25px;
}

.header_socials a {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: rgb(255, 255, 255);
}
.header_socials > a:hover {
  color: var(--color-primary);
}
.header-image-link-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}

/*----------ME--------------*/

.me {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.me > img {
  height: 350px;
  width: 200px;
  object-fit: contain;
}

.fixedContentBox {
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

.fixedContentBox h1 {
  font-size: 3rem;
}
.project-fixed-details {
  display: flex;
  justify-content: space-between;
}
.project-fixed-details h2 {
  color: #fff;
  font-size: 2.5rem;
}
.fixedContentBox p {
  color: #03121e;
  font-weight: 500;
  line-height: 1;
}
.project-fixed-details p {
  color: #eeee;
  font-weight: 400;
}

/*----------SCROLL DOWN--------------*/

.right-scroll-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: fit-content;
  right: 0;
  overflow: hidden;
  overflow-y: scroll;
  backdrop-filter: blur(4px);
}

/* media quries (small)*/

@media screen and (max-width: 980px) {
  .header_container {
    width: 100%;
    border: none;
    height: fit-content;
    gap: 10%;
    padding-bottom: 3vh;
  }
  .header_container h1 {
    font-size: 5rem;
  }
  .whole-wrapper {
    margin-top: 0px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .fixedContentBox h1 {
    font-size: 3rem;
  }
  .right-scroll-container {
    margin-top: 0px;
  }
}
