@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,400;1,500&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  scroll-behavior: smooth;
}


@font-face {
  font-family: "Bella";
  src: local("BellaRegular"),
    url("./fonts/bella.regular.ttf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "Gilroy";
  src: local("GilroyRegular"),
    url("./fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: 100;
}
/* Set the width and color of the scrollbar */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

:root {
  --color-bg: #0a3e63;
  --color-bg-variant: #2c2c6c;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-light: rgba(259, 255, 255, 0.6);

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 89%;
  --container-width-sm: 95%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Poppins", sans-serif;
  color: white;

}

/* gerneral styles */

.container {
  width: var(--container-width-lg);
  margin: auto;

}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  color: var(--color-primary);
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  cursor: pointer;
}

.btn {
  width: max-content;
  display: inline-block;
  color: #fff;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  background-color: var(--color-primary);
  transition: var(--transition);
  font-size: 18px;
  font-family: "Bella";
}

.btn :hover {
  background-color: var(--color-primary);
  color: var(--color-bg);
  border-color: transparent;
}


/* media quries (medium)*/

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
}

/* media quries (small)*/

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-ms);
  }
}
