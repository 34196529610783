.homegalery-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  font-family:  "Gilroy";
}

.homegalery-wrapper .headingWrapper {
  width: 100%;
  padding: 0px 8%;
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-primary);
   font-family: "Bella";
}
.honeycomb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  max-width: 1100px;
  margin: 0 auto 100px;
  padding: 0;
  transform: translateY(34.375px);
}
.honeycomb-cell {
  flex: 0 1 220px;
  max-width: 230px;
  height: 120px;
  margin: 65px 12px 25px;
  position: relative;
  padding: 0.5em;
  text-align: center;
  z-index: 1;
}
.honeycomb-cell__title {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 1.75em;
  transition: opacity 350ms;
}
.honeycomb-cell__title > small {
  font-weight: 300;
  margin-top: 0.25em;
}
.honeycomb-cell__image {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.honeycomb-cell::before,
.honeycomb-cell::after {
  content: "";
}
.honeycomb-cell::before,
.honeycomb-cell::after,
.honeycomb-cell__image {
  top: -50%;
  left: 0;
  width: 100%;
  height: 200%;
  display: block;
  position: absolute;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  z-index: -1;
}
.honeycomb-cell::before {
  background: #fff;
  transform: scale(1.055);
}
.honeycomb-cell::after {
  background: #000000;
  opacity: 0.4;
  transition: opacity 400ms;
}
.honeycomb-cell:hover .honeycomb-cell__title {
  opacity: 0;
}
.honeycomb-cell:hover::before {
  background: var(--color-primary);
}

.honeycomb__placeholder {
  display: none;
  opacity: 0;
  width: 250px;
  margin: 0 12.5px;
}

.portfolio-view-button {
  margin-top: 10px;
  background-color: var(--color-primary);
  color: white;
  border-radius: 15px;
  padding: 13px 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  font-family: "Bella";
  a {
    color: white;
    z-index: 666;
    position: relative;
  }
}
@media (min-width: 300px) and (max-width: 550px) {
  .honeycomb-cell:nth-child(3n) {
    margin-right: calc(50% - 125px);
    margin-left: calc(50% - 125px);
  }

  .honeycomb__placeholder:nth-child(3n + 5) {
    display: block;
  }
}

@media (min-width: 550px) and (max-width: 960px) {
  .honeycomb-cell:nth-child(3n) {
    margin-right: calc(50% - 125px);
    margin-left: calc(50% - 125px);
  }

  .honeycomb__placeholder:nth-child(3n + 5) {
    display: block;
  }
}
@media (min-width: 960px) and (max-width: 1100px) {
  .honeycomb-cell:nth-child(5n + 4) {
    margin-left: calc(50% - 252px);
  }

  .honeycomb-cell:nth-child(5n + 5) {
    margin-right: calc(50% - 260px);
  }

  .honeycomb__placeholder:nth-child(5n),
  .honeycomb__placeholder:nth-child(5n + 3) {
    display: block;
  }
}

@media screen and (max-width: 1400px) {
  .honeycomb-cell {
    flex: 0 1 200px;
    max-width: 180px;
    height: 100px;
    margin: 65px 8px 5px;
  }
  .honeycomb-cell__title {
    font-size: 1rem;
  }
  .honeycomb {
    max-width: 900px;
  }
}
@media screen and (max-width: 1185px) {
  .honeycomb-cell {
    flex: 0 1 200px;
    max-width: 160px;
    height: 80px;
    margin: 60px 8px 0px;
  }
}
@media screen and (max-width: 1100px) {
  .honeycomb {
    max-width: 520px;
    margin-bottom: -30px;
  }
}
@media screen and (max-width: 960px) {
  .honeycomb {
    margin-bottom: 100px;
  }
}

@media (max-width: 550px) {
  .honeycomb-cell {
    margin: 25px 5px;
  }
  .honeycomb {
    margin-bottom: 50px;
  }
  .homegalery-wrapper {
    gap: 20px;
  }
}
