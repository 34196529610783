.toolsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  padding: 0px 8%;
  gap: 30px;
}
.toolsWrapper > h2 {
  font-size: 35px;
  width: 100%;

  text-transform: uppercase;
  color: var(--color-primary);

  font-family: "Bella";
}

.toolsWrapper > div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.toolsWrapper > div img {
  width: 75px;
  object-fit: cover;
  border-radius: 30%;
}
