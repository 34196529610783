footer {
  padding: 3rem 0;
  text-align: center;
  font: size 0.9rem;
}

footer a {
  color: white;
  font-family: "Bella";
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 2rem;
}

.footer_socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.footer_socials a{
    background:black;
    color:white;
    font-size:2rem;
    padding:0.5rem;
    border-radius:0.7rem;
    display:flex;
    border:1px solid transparent;
}

.footer_socials a:hover{
    background:transparent;
    color:var(--color-bg);
    border-color:var(--color-bg);
}

.footer_copyright{
    margin-bottom:1rem;
    color:white;
}

 /* media quries (small)*/
  
 @media screen and (max-width: 600px) {
    .permalinks{
        flex-direction:column;
        gap:1.5rem;
    }

    .footer_socials{
        margin-bottom:2.6rem;
    }
 }