.about-section {
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 5vh 8%;
  width: 100%;
  background-color: var(--color-primary);
  gap: 3vh;
}
.about-heading {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.about-heading > h2 {
  font-size: 35px;
  color: #fff;

  font-family: "Bella";
}
.about_container {
  gap: 15%;
  width: 100%;
  margin: auto;
}

.about_container p {
  color: #fff;
  text-align: justify;
  font-size: 18px;
}

/* media quries (medium)*/

@media screen and (max-width: 1024px) {
  .about_container {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .about_me {
    width: 50%;
    margin: 2rem auto 4 rem 0;
  }

  .about_content p {
    margin: 1rem 0 1.5rem;
  }
  .about_container {
    gap: 5%;
  }
}

/* media quries (small)*/

@media screen and (max-width: 990px) {
  .about_cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .about_content {
    text-align: justify;
  }
}
