.gallery-wrapper{
    background-color: white;
}


.gallery-wrapper ul {
  display: flex;
  flex-wrap: wrap;
}

.gallery-wrapper li {
  height: 40vh;
  flex-grow: 1;
}

.gallery-wrapper li:last-child {
  flex-grow: 10;
}

.gallery-wrapper img {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}



@media (max-aspect-ratio: 1/1) {
  .gallery-wrapper li {
    height: 30vh;
  }
}


@media (max-height: 480px) {
  .gallery-wrapper li {
    height: 80vh;
  }
}


@media (max-aspect-ratio: 1/1) and (max-width: 480px) {
  .gallery-wrapper ul {
    flex-direction: row;
  }

  .gallery-wrapper li {
    height: auto;
    width: 100%;
  }
  .gallery-wrapper img {
    width: 100%;
    max-height: 75vh;
    min-width: 0;
  }
}