.portfolio-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;

  width: 100%;
}
.portfolio-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px;
  text-transform: uppercase;
  padding: 0px 8%;
}

.portfolio-header > h2 {
  font-size: 35px;
  width: 100%;
  font-family: "Bella";
  color: var(--color-primary);
}

.portfolio_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0px 8%;
  width: 100%;

}

.portfolio_item {
  padding: 1rem;

  transition: var(--transition);
  height: fit-content;
  min-height: 380px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.portfolio_item_image {
  height: 200px;
  border-radius: 1.5rem;
  overflow: hidden;
}
.portfolio_item_image > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.portfolio_item h3 {
  margin: 1.2rem 0;
  font-size: 20px;
}

.portfolio_item_cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}
.portfolio_item_cta > a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  
}

.portfolio_item_cta > a:hover {
  border-color: white;
  background-color: white;
  color: black;
}

/* media quries (medium)*/

@media screen and (max-width: 1024px) {
}

/* media quries (small)*/

@media screen and (max-width: 410px) {
  .portfolio_item {

  width: 350px;

}
.portfolio_container {

  padding: 0px 5%;
}
}
