.sevice-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  height: fit-content;
  margin-bottom: 50px;
}
.service-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 0px 8%;
}

.service-header > h2 {
  font-size: 35px;
  text-transform: uppercase;
  font-family: "Bella";
  color: var(--color-primary);
}

.services_container {
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.service {
  border-radius: 0.5rem 2rem;
  border: 1px solid var(--color-primary);
  height: fit-content;
  width: fit-content;
  transition: var(--transition);
  max-width: 450px;
}

.service_head {
  color: var(--color-primary);
  padding: 2rem 1rem 0rem;

  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service_head h3 {
  font-size: 1.3rem;
  text-align: center;
  font-family: "Bella";

}
.service_list {
  padding: 2rem;
}

.service_list li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}

.service_list_icon {
  color: var(--color-primary);
  margin-top: 2px;
}

.service_list p {
  font-size: 0.9rem;
}

/* media quries (medium)*/

@media screen and (max-width: 850px) {
  .service {
    width: 330px;
  }
}

/* media quries (small)*/

@media screen and (max-width: 600px) {
}
