.navbar {
  background-color: rgba(0, 0, 0, 0.3);
  width: max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;

  transform: translateX(-50%);
  top: 88%;
  left: 50%;
  display: flex;
  flex-direction: row;
  gap: 1.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
}

.navbar a {
  background: var(--color-primary);
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  color: var(--color-light);
  font-size: 1.1rem;
  color: white;
}


